import { Component, OnInit } from '@angular/core';
import { faHeartbeat, faCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  constructor() { }
  faHeartbeat = faHeartbeat;
  faCircle = faCircle;
  ngOnInit(): void {
  }

}
