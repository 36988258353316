import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { HeaderComponent } from '../../common/header/header.component';

@Component({
  selector: 'app-platform',
  templateUrl: './platform.component.html',
  styleUrls: ['./platform.component.css']
})
export class PlatformComponent extends HeaderComponent implements OnInit {

  public pltName;
  public route: ActivatedRoute;
  public router: Router;

  constructor() {
    super();
    console.log(this.platformName);
  }

  
  
  //  titleGenerator(){
  //   if (this.router.isActive('/platform/ihealthage', true)) {
  //     this.platformName = 'iHealthAge Platform';
  //   } else if (this.router.isActive('/platform/ihealthchain', true)) {
  //     this.platformName = 'iHealthChain Platform';
  //   } 
  //   else if (this.router.isActive('platform/ihealthage/procedure', true)) {
  //     this.platformName = 'iHealthChain Platform';
  //   } 
  //   else if (this.router.isActive('platform/ihealthage/reports', true)) {
  //     this.platformName= 'iHealthChain Platform';
  //   } 
  // }

  ngOnInit(): void {
  }

}
