import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/common/header/header.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { Home1Component } from './components/pages/home1/home1.component';
import { Home2Component } from './components/pages/home2/home2.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { PortfolioComponent } from './components/pages/portfolio/portfolio.component';
import { BloglistComponent } from './components/pages/bloglist/bloglist.component';
import { BlogdetailsComponent } from './components/pages/blogdetails/blogdetails.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxUiLoaderModule, NgxUiLoaderConfig,
  SPINNER,
  POSITION,
  PB_DIRECTION } from 'ngx-ui-loader';

import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { PlatformComponent } from './components/pages/platform/platform.component';
import { IheathageComponent } from './components/pages/platform/iheathage/iheathage.component';
import { IhealthchainComponent } from './components/pages/platform/ihealthchain/ihealthchain.component';
import { IheathgeneComponent } from './components/pages/platform/iheathgene/iheathgene.component';
import { EmuseplatformComponent } from './components/pages/platform/emuseplatform/emuseplatform.component';
import { PcthocplatformComponent } from './components/pages/platform/pcthocplatform/pcthocplatform.component';
import { HealthAgeComponent } from './components/pages/platform/iheathage/health-age/health-age.component';
import { ScreensComponent } from './components/pages/platform/iheathage/screens/screens.component';
import { ProcedureComponent } from './components/pages/platform/iheathage/procedure/procedure.component';
import { ReportsComponent } from './components/pages/platform/iheathage/reports/reports.component';
import { ChartsModule } from 'ng2-charts';
import { FormsModule } from '@angular/forms';

const ngxUiLoaderConfig: NgxUiLoaderConfig =
{
  bgsColor: '#119e00',
  bgsOpacity: 0.7,
  bgsPosition: 'center-center',
  bgsSize: 70,
  bgsType: 'three-bounce',
  blur: 5,
  delay: 0,
  fastFadeOut: true,
  fgsColor: 'red',
  fgsPosition: 'center-center',
  fgsSize: 60,
  fgsType: 'ball-spin-clockwise',
  gap: 36,
  logoPosition: 'center-center',
  logoSize: 120,
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: 'rgba(40, 40, 40, 0.8)',
  pbColor: 'red',
  pbDirection: 'rtl',
  pbThickness: 2,
  hasProgressBar: true,
  text: 'Sending',
  textColor: '#FFFFFF',
  textPosition: 'center-center',
  maxTime: -1,
  minTime: 300
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    Home1Component,
    Home2Component,
    AboutComponent,
    ServicesComponent,
    PortfolioComponent,
    BloglistComponent,
    BlogdetailsComponent,
    ContactComponent,
    PlatformComponent,
    IheathageComponent,
    IhealthchainComponent,
    IheathgeneComponent,
    EmuseplatformComponent,
    PcthocplatformComponent,
    HealthAgeComponent,
    ScreensComponent,
    ProcedureComponent,
    ReportsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
