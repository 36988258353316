<div id="myCarousel" class="carousel slide banner-2" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active"> <img class="first-slide img-fluid" src="assets/images/our_service_pic_header.jpeg" alt="First slide">
      <div class="container">
        <div class="carousel-caption banner1 text-left">
          <h2 class="wow fadeInUp">Services</h2>
          <p class="breadcrumb-item"><a href="#">Home</a> / Services</p>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="section-padding-white">
  <div class="container">
    <div class="row">
     <div class="col-md-6 service_1 wow fadeInLeft">
       <h3>Omics Data Analysis Solution Using Deep Learning</h3>
       <p>In the diagnosis and research of cancer, the identification and determination of cancerous status or tissue origin of the tissues/samples is costly and time consuming, and requires abundant knowledge and experience.</p>
       <p>Karte has successfully developed a deep learning algorithm-based prediction model that accurately predicts cancer presence or absence and tissue category using cancer-related data such as gene expression data. This prediction model has excellent performance in terms of accuracy compared with existing life information analysis and machine learning algorithms, and Karte can conduct high-level research in the cancer genome field by applying it.</p>

     </div>
     <div class="col-md-6 wow fadeInRight">

      <img class="img-fluid service_img_1_style" src="assets/images/service_img_1.png">
    </div>
    <div class="col-md-6 space wow fadeInLeft">

      <img class="img-fluid service_img_1_style" src="assets/images/service_img_2.png">
    </div>
    <div class="col-md-6 service_2 space wow fadeInRight">
      <h3>AI-based Multi-Omics Big Data Analysis Service</h3>
      <p>Large-scale infrastructure and advanced analysis technology are needed to analyze multi-omics data.
        Karte can design new analysis models for complicated interactions of various omics data using AI-based machine learning and deep learning technologies.</p>
      <p><b>IMPROVEMENT OF ACCURACY WITH THE USE OF MULTI-OMICS DATA</b></p>
      <p>As data types and amounts increase, internal information amounts increase and complicated interrelations come into being between data.
        Therefore, it is important to find an exact and optimized model to utilize meaningful data.
        Karte provides optimal solutions by combining and structuring models suitable for data to produce the best results.</p>

    </div>
    <div class="col-md-6 service_1 space wow fadeInLeft">
      <h3>Big Data Solution for Biomarker Discovery and Evaluation</h3>
      <p><b>PREDICTION OF TUMOR MUTATION BURDEN (TMB) / NEOANTIGENS</b></p>
      <p>Recently, cancer treatment methods based on immuno-oncology are emerging. Karte obtains data by sequencing the genome collected from the tumors of patients and discovers biomarkers that can suggest the most suitable treatment method for the patients using the data. With the prediction of tumor mutation burden (TMB), one of the biomarkers, customized treatment methods can be analyzed promptly and accurately.</p>
      <p>The new cancer vaccine includes 10 to 20 different specific variation proteins and neoantigens by each tumor of a patient. These proteins are not found in healthy cells and play the role of enhancing the effect of T-cells on attacks on cancer cells. Therefore, it is critical to predict if this protein is present in a cell for effective vaccine treatment.</p>
      <p><b>DEVELOPMENT AND EVALUATION OF BIOMARKERS</b></p>
      <p>If biomarkers for target drugs are discovered during drug development, the development success rate can be increased by classifying patients who react well to the drugs during clinical trials using the biomarkers. Karte provides such a service as part of its data-based solution business for customized treatments for patients.</p>

    </div>
    <div class="col-md-6 space wow fadeInRight">
      <img class="img-fluid service_img_1_style space" src="assets/images/service_img_3_1.png">
     <img class="img-fluid service_img_1_style space" src="assets/images/service_img_3_2.png">
   </div>
   <div class="col-md-6 space wow fadeInLeft">

     <img class="img-fluid service_img_1_style" src="assets/images/service_img_4.png">
   </div>
   <div class="col-md-6 service_2 space wow fadeInRight">
     <h3>Big Data Consulting Service</h3>
     
     <p>Along with the recent production of various and abundant omics big data due to the development of genome technology and low production costs, new research is actively being conducted. However, due to rapid increases in the amount of data, researchers have difficulty storing, managing, and analyzing it.
      Karte can support the best solutions for researchers to successfully utilize big data as it has data resources, processing/treatment, technology, and analysis/utilization manpower.</p>

   </div>
    
    </div>
  </div>
</section>

 