
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HeaderComponent } from './components/common/header/header.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { Home1Component } from './components/pages/home1/home1.component';
// import { Home2Component } from './components/pages/home2/home2.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { PortfolioComponent } from './components/pages/portfolio/portfolio.component';
import { BloglistComponent } from './components/pages/bloglist/bloglist.component';
import { BlogdetailsComponent } from './components/pages/blogdetails/blogdetails.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { PlatformComponent } from './components/pages/platform/platform.component';
import { IheathageComponent } from './components/pages/platform/iheathage/iheathage.component';
import { IhealthchainComponent } from './components/pages/platform/ihealthchain/ihealthchain.component';
import { IheathgeneComponent } from './components/pages/platform/iheathgene/iheathgene.component';
import { EmuseplatformComponent } from './components/pages/platform/emuseplatform/emuseplatform.component';
import { PcthocplatformComponent } from './components/pages/platform/pcthocplatform/pcthocplatform.component';
import { HealthAgeComponent } from './components/pages/platform/iheathage/health-age/health-age.component';
import { ScreensComponent } from './components/pages/platform/iheathage/screens/screens.component';
import { ProcedureComponent } from './components/pages/platform/iheathage/procedure/procedure.component';
import { ReportsComponent } from './components/pages/platform/iheathage/reports/reports.component';



const routes: Routes = [
	{path: '', component: Home1Component},
    // {path: 'home1', component: Home1Component},
	{path: 'about', component: AboutComponent},
	{path: 'platform', component: PlatformComponent,
		 children: [
			 { path: 'ihealthage', component: IheathageComponent,
			  children: [
				  {path: 'healthaging', component: HealthAgeComponent},
				  {path: 'screens', component: ScreensComponent},
				  {path: 'procedure', component: ProcedureComponent},
				  {path: 'reports', component: ReportsComponent}
			  ]
			},
			 { path: 'ihealthchain', component: IhealthchainComponent},
			 { path: 'ihealthgene', component: IheathgeneComponent},
			 { path: 'emuse_platform', component: EmuseplatformComponent},
			 { path: 'pcthoc_rightgene_platform', component: PcthocplatformComponent},

		 ]
	 
    },
	{path: 'services', component: ServicesComponent},
	{path: 'ourscience', component: PortfolioComponent},
	{path: 'bloglist', component: BloglistComponent},
	{path: 'blogdetails', component: BlogdetailsComponent},
	{path: 'contact', component: ContactComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
