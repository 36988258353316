<footer class="footer">
	<div class="container">
    <div class="row">
    	<div class="col-lg-4">
        	<div class="footer-item">
            	<h3 class="footer_comapny_name">Karte Corporation</h3>
                <p>Karte Corporation in association with Govita Tech was founded by leaders in life science R&D and Artificial Intelligence (AI) with the ultimate goal of becoming a world-class technology driven company focusing on the development and analysis of molecular biomarkers, associating test data with individual phenotype, with clinicians as partners providing personalized interventions to improve health, delay aging and enhance overall quality of life.</p>
                
            </div>
            <div class="footer-item">
            <ul class="social">
            	<li><a href="https://www.facebook.com/ihealthomics.genetics.1" target="blank"><i class="icofont icofont-social-facebook"></i></a></li>
                <li><a href="https://twitter.com/ihealthomics" target="blank"><i class="icofont icofont-social-twitter"></i></a></li>
                <li><a href="https://www.linkedin.com/in/ihealthomics-corporation-limited-11b09719b/" target="blank"><i class="icofont icofont-social-linkedin"></i></a></li>
                
            </ul>    
            </div>
        </div>
        <div class="col-lg-4">
        	<div class="footer-item">
        		<h4>Quick Links</h4>
                <ul>
                	<li><a routerLink="/">Home</a></li>
                    <li><a routerLink="/about">About Us</a></li>
                    <li><a routerLink="/services">Services</a></li>
                    <li><a routerLink="/platform">Our Platform</a></li>
                    <li><a routerLink="/ourscience">Our Science</a></li>
                    <li><a routerLink="/contact">Contact</a></li>
                </ul>
            </div>
        </div>
        <!-- <div class="col-lg-3">
        	<div class="footer-item">
        		<h4>Our Services</h4>
                <ul>
                	<li><a href="#">Omics Data Analysis Solution Using Deep Learning</a></li>
                    <li><a href="#">AI-based Multi-Omics Big Data Analysis Service</a></li>
                    <li><a href="#">Big Data Solution for Biomarker Discovery and Evaluation</a></li>
                    <li><a href="#">Big Data Consulting Service</a></li>
                </ul>
            </div>
        </div> -->
        <div class="col-lg-4">
            <div class="footer-item">
                <h4>Contact Us</h4>
                
                <p><b>Karte Corporation</b></p>
                <p>Unit 19, 8/F, Tai Tak Industrial Building,<br> 2-12 Kwai Fat Road, Kwai Chung,<br> New Territories, Hong Kong, China</p>
<br>
<h6 style="color:#d8d8d8;">Phone:</h6>
                <p>+852 6609 9885 (Hong Kong)<br>

                    +1 407 768 3838 (USA)
                    
                    </p>
                    <br>
                    <p>info@karte.com.hk</p>
            </div>
        </div>
        
        <!-- <div class="col-lg-3">
            <div class="footer-item">
                <h4>Science Blogs</h4>
                <ul>
                	<li><a href="#">What is Lorem Ipsum?</a></li>
                    <li><a href="#">Why do we use it?</a></li>
                    <li><a href="#">There are many variations</a></li>
                    <li><a href="#">passages of Lorem Ipsum</a></li>
                    <li><a href="#">Contrary to popular</a></li>
                    <li><a href="#">Lorem Ipsum is not simply</a></li>
                </ul>
            </div>  
                  
        </div> -->
        </div>
    <div class="row">
    	<div class="col-lg-12">
        	<div class="footer-bottom">
            	<p>Copyright © 2022-25, <a routerLink="/" class="footer_link">Karte Corporation</a> | All Rights Reserved</p>
            </div>
        </div>
    </div>    
    </div>
</footer>
<div id="qnimate" class="off">
  <div id="search" class="open">
    <button data-widget="remove" id="removeClass" class="close" type="button">×</button>
    <form action="#" method="post" autocomplete="off">
      <input type="text" placeholder="Type search keywords here" value="" name="term" id="term">
      <button class="btn btn-primary btn-xl" type="submit"><span class="glyphicon glyphicon-search"></span> Search</button>
    </form>
  </div>
</div>