<div class="container">
    <div class="row">
        <div class="col-md-12">
            <h2 class="plactform_heading"> EUMUSE Platform</h2>
        </div>
       
        <div class="col-md-2 unselected_btn">
            <a href="https://www.eumuse.com/" target="blank"><p class="unselected_para">Marina De Moses</p></a>
        </div>
        <div class="col-md-2 unselected_btn">
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSd4hbHBXCZEkgYrWxoLbRICzqZvb9SN0mS0C9wtdX9Hh6C-Ng/viewform" target="blank"><p class="unselected_para">Before you listen</p></a>
        </div>
        <div class="col-md-2 selected_btn">
            <p class="selected_para">When You Listen</p>
        </div>
        <div class="col-md-2 unselected_btn">
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSfdRl18RJRmJmkEdWPNZ6DkTGUpwdj7UvEsW-Sxkcz2kT3L8g/viewform" target="blank"><p class="unselected_para">After you listen</p></a>
        </div>
        <div class="col-md-2 unselected_btn">
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSc5XzeFqxR3ad6Dbc_nT9nG7XGeFGzA_9nz38i2UrsTNxB4Uw/viewform" target="blank"><p class="unselected_para">Analyze the results</p></a>
        </div>
        <div class="col-md-12 heading_style">
            <h3>EuMuse – Mindful Music Listening Program</h3>
        </div>
        <div class="col-md-12">
            <img src="assets/images/emuse_1.png" style="
            width: 100%;">
        </div>
        <div class="col-md-12">
            <p>The two most prominent tools that we have as conscious beings – our <b>attention</b> and our <b>intention</b>, combined determine the quality of our listening experience.</p>
            <p>There is no listening in the future and there is no listening in the past thus mindful listening helps us to consciously accumulate present moment awareness</p>
        </div>
        <div class="col-md-12">
            <p><b>Listening</b> is an active response to the phenomena of sound; it focuses in on certain sounds to the exclusion of others. It involves the will as well as a neurological process.</p>
        </div>
        <div class="col-md-12">
            <p><b>Conscious application of music</b>  - mindful music listening i.e. having music in our life by choice and design is a skill attained through the same  deliberate practice as any other pursuit of human excellence</p>
        </div>
        <div class="col-md-12">
            <h3 class="heading_style_2">
                How to Listen Mindfully? 
            </h3>
        </div>
        <div class="col-md-12">
            <p>It is simple to do and does not require much effort on your part, even if you practice it for just 5 to 10 minutes a day.</p>
            <p>Here are few tips to help you listen with focused mind:</p>
        </div>
        <div class="col-md-6">
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style"><b>Dedicate time to listen:</b> Usually the act of listening music is accompanied by other tasks like driving, cooking, cleaning, talking to friends, reading a book, etc. But just like meditation, mindful listening would require you to create space for the act of listening; dropping all the other things. A silent place or a place where you will not be disturbed, is preferable. If there are external sounds, it is okay, stay calm and focused.</p>
            </div>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style"><b>Witness:</b> Often we miss the real essence of listening to music and slip into the semi conscious world of listening. In this step we will stop and witness the music composition, paying attention to the melody, rhythm, dynamics, emotions, and/or memories  that music triggers in us.</p>
            </div>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style"><b>Notice your emotions: </b>  Notice how you breathe. Notice the movement in your body. Notice your feelings – are you feeling happy, sad, excited? Are you feeling the urge to listen more?</p>
            </div>
        </div>
        <div class="col-md-6">
            <img src="assets/images/emuse_2.png" style="
            width: 88%;">
        </div>
        <div class="col-md-6">
            <img src="assets/images/emuse_3.png" style="
            width: 88%;">
        </div>
        <div class="col-md-6">
            <p><b>Process:</b></p>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">calm down your mind and body – come to quiet for a minute</p>
            </div>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">be (remain) perceptive to new impression</p>
            </div>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">feel the music – surrender to the music – be open - let it surprise you</p>
            </div>
        </div>
        <div class="col-md-6">
            <h3 class="heading_style_2">EuMuse Music Modules</h3>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Relax</p>
            </div>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Energize</p>
            </div>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Focus-Learn</p>
            </div>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Create-Reflect </p>
            </div>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Exercise</p>
            </div>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Uplift-Change your Mood.</p>
            </div>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Strengthen the Healing Process  </p>
            </div>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">For Babies and Expecting Mothers   </p>
            </div>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Expend your Sonic Horizon</p>
            </div>
        </div>
        <div class="col-md-6">
            <img src="assets/images/emuse_4.png" style="
            width: 88%;">
        </div>
        <div class="col-md-12">
            <h3 class="heading_style_2">
               1. Relax -   REDUCE STRESS
            </h3>
        </div>
        <div class="col-md-6">
            <img src="assets/images/emuse_5.png" style="
            width: 88%;">
        </div>
        <div class="col-md-6 space_top">
            <p><b>Scientific research:</b></p>
            <p>Significant reductions in anxiety and sympathetic nervous system activity, relaxation of tense muscles;  decrease of the amount of the cortisol, a stress related hormone produced by the body in response to stress; reduces effect of negative emotions, calms blood pressure and heart rate, changes the perception of time to make it seem pass more slowly; listening before bedtime helps decreasing insomnia.</p>
            <p><b>Use: </b>these modules -  if you are nervous, anxious, worried, unsettled, overworked, on edge, when you seek balance i.e. equilibrium between your outer and inner world, wish to de-clutter your mind. This relaxed calm gives you a sense of internal composure and emotional balance.</p>
            <p><b>Listening strategy:</b>Sit or lie down in a comfortable position, in a place where you will not be disturbed. You can feel refreshed after listening for as little as 10 minutes.</p>
        </div>

        <div class="col-md-12">
            <h3 class="heading_style_2">
               2. ENERGIZE
            </h3>
        </div>
        
        <div class="col-md-6 space_top">
            <p><b>Scientific research:</b></p>
            <p>Increase of physical vitality, relief of fatigue and inertia, helps in gaining focus and strength, clears out emotional tensions and blockag</p>
            <p><b>Use: </b>these modules – when you need to regain focus , courage and energy, when you wish to prime yourself for success by elevating your mood before a speech or meeting.</p>
            <p><b>Listening strategy:</b>Sit or stand for 10-15 minutes in a place where you will not be disturbed. Let your body move as you feel the music</p>
        </div>
        <div class="col-md-6">
            <img src="assets/images/emuse_6.png" style="
            width: 70%;">
        </div>

        <div class="col-md-12">
            <h3 class="heading_style_2">
               3. FOCUS - LEARN – MEMORIZE
            </h3>
        </div>
        <div class="col-md-6">
            <img src="assets/images/emuse_7.png" style="
            width: 88%;">
        </div>
        <div class="col-md-6">
            <p><b>Scientific research:</b></p>
            <p>Increases learning ability, improves children’s literacy, numeracy, brain function, fine motor skills;  increases concentration, communication, creativity;  strengthens mental power, critical thinking and collaboration.</p>
            <p><b>Use: </b>these modules when you need to focus, increase attention levels, improve retention and memory, extend focused learning time, expand thinking skills, strengthen your mental power.</p>
            <p><b>Listening strategy:</b>listen as background music when you study, read, or write; replay the same music modules when re-reading the material for exams</p>
        </div>

        <div class="col-md-12">
            <h3 class="heading_style_2">
               4. CREATE – REFLECT
            </h3>
        </div>
        
        <div class="col-md-6 space_top">
            <p><b>Scientific research:</b></p>
            <p><b>The multi sensory nature of music stimulates unique neuroplasticity, including improvement of brain capacities unrelated to music such as learning new sensory and motor skills, and abstract concepts;</b>  alert listeners have brain changes, both the activations noted in regions of self motivation and action, sensorimotor, and language; and decreases in the executive, evaluation centers;   the “eureka” moment is related to dopamine reward centers;   mindfulness improves insight problem solving, i.e.  seeing and solving problems in a novel way, and reduces cognitive rigidity i.e. tendency to overlook novel and adaptive ways or responding due to past experience;  rhythm has profound effects on the brain, including  influencing perception and thinking.</p>
            <p><b>Use: </b>these modules when you need to stimulate sensitivity and boost your creativity, appeal to new thoughts  and ideas using observant and alert mind, contemplate on goal-setting and problem-solving.</p>
            <p><b>Listening strategy:</b>Listen as background music for activities such as writing, painting, journaling, brainstorming.</p>
        </div>
        <div class="col-md-6">
            <img src="assets/images/emuse_8.png" style="
            width: 88%;">
        </div>

        <div class="col-md-12">
            <h3 class="heading_style_2">
               5. EXERCISE  – energize your physical body
            </h3>
        </div>
        <div class="col-md-6">
            <img src="assets/images/emuse_9.png" style="
            width: 88%;">
        </div>
        <div class="col-md-6 space_top">
            <p><b>Scientific research:</b></p>
            <p>Improvement in body movement and motor coordination,  positive effects of listening to music during warm-up and resistance exercise on physiological (heart rate and blood pressure) and psychophysical (rating of perceived exertion) responses in trained athletes, increase in levels of psychological arousal, reduction in the feeling of fatigue, improved physiological relaxation response; increased efficiency in the performance of repetitive work tasks, positive effect on the rehabilitation of cardiovascular, central, musculoskeletal and psychological fatigue , elimination of exercise-induced fatigue symptoms caused by monotonous work.</p>
            <p><b>Use: </b>these modules when you exercise or wish to improve productivity and encourage movement activities.</p>
            <p><b>Listening strategy:</b>use as a background music; or use as a music break to provide a sound stimulation  to increase attention and make exercise more fun.</p>
        </div>

        <div class="col-md-12">
            <h3 class="heading_style_2">
               6. UPLIFT –  CHANGE  YOUR MOOD
            </h3>
        </div>
        
        <div class="col-md-6 space_top">
            <p><b>Scientific research:</b></p>
            <p>great reduction in symptoms of depression, significant improvements in mood, social functioning and quality of life; music stimulates all brain regions that are considered the core of emotional processing; strong connections from the amygdala to the dopamine driven nucleus accumbens (pleasure, reward, addiction center);  the production of new brain cells in the hippocampus increases during recovery from depression and stress; nucleus accumbens is highly correlated with pleasure and is responsible for the chills – skin “goose bumps” associated with musical moments.</p>
            <p><b>Use: </b>when you need to escape from negative thought patterns, break bad moods, uplift the spirit and shift a negative attitude to a more optimistic outlook; when you need occasional break to recharge and to refocus your attention with simple stimuli</p>
            <p><b>Listening strategy:</b>Sit or stand for 10-15 minutes in a place where you will not be disturbed. Let your body move as you feel the music.</p>
        </div>
        <div class="col-md-6">
            <img src="assets/images/emuse_10.png" style="
            width: 88%;">
        </div>

        <div class="col-md-12">
            <h3 class="heading_style_2">
               7. STRENGTHEN THE HEALING PROCESS
            </h3>
        </div>
        <div class="col-md-6">
            <img src="assets/images/emuse_11.png" style="
            width: 88%;">
        </div>
        <div class="col-md-6 space_top">
            <p><b>Scientific research:</b></p>
            <p>Helps reduce the sensation and distress of chronic and postoperative pain;  complements the use of anesthesia during surgery, enhances the effects of analgesics, reduces chronic pain from a range of painful conditions (including rheumatoid arthritis by up to 21%; and depression by up to 25%); lowers the high blood pressure; creates immediate and positive effect on oxygen saturation levels, heart and respiration rates  of premature infants; decreases postpartum depression, induces a positive and profound emotional experience which leads to secretion of immune-boosting hormones.</p>
            <p><b>Use: </b>when you wish to enhance the healing process, decrease the use of analgesics, lower the high blood pressure, boost your immune system, bring temporary relief from both short-term pain and long-term discomforts; reduce intensity, frequency and duration of headaches; improve your sleep.</p>
            <p><b>Listening strategy:</b>sit or lie in a comfortable position while the music plays. Take at least 15-20 minutes to concentrate fully on the music. Listen with full attention.</p>
        </div>

        <div class="col-md-12">
            <h3 class="heading_style_2">
               8. FOR BABIES & EXPECTING MOTHERS
            </h3>
        </div>
        
        <div class="col-md-6 space_top">
            <p><b>Scientific research:</b></p>
            <p>With music inherently multi modal, music is the ideal brain training for children;   changes in higher order cognitive functions can occur in days, weeks and years;    babies appear to have a neural basis of understanding music, infants respond to pitch and melody, they can distinguish scales, chords and consonant combinations;  babies recognize tunes played to them for several days;   very young children can perceive complex music rhythms from different cultures but lose this ability if not exposed to it before age one;  music has an immediate and positive effect on the oxygen saturation levels, heart and respiration rates of premature infants; <b>fetuses are conditioned to music perception while still in the womb by the observed intense physiological music responses of the mothers, from 28 weeks, i.e. at the start of the third trimester of pregnancy, the heart rate of the fetus already changes when it hears a familiar song,  from 35 weeks, there is  even a change in its movement patterns; </b> 
                  after training in childhood 1 to 5 years, adults increase brain response to complex sounds, they are more able to hear the fundamental (lowest frequency in a sound,) crucial for speech and music perception,  these people also hear better in noisy places and are better listeners later in life; <b>when the musical training begins before age seven, neural systems are created that last a lifetime.</b> </p>
            <p><b>Use: </b>when you wish to instill nurturing atmosphere in your home; deepen the sense of growth and wellbeing, regain the sense of peace or creatively alter sonic environment.</p>
            <p><b>Listening strategy:</b>use these music modules as background music during wake-time  and sleep hours of babies.</p>
        </div>
        <div class="col-md-6">
            <img src="assets/images/emuse_12.png" style="
            width: 88%;">
        </div>

        <div class="col-md-12">
            <h3 class="heading_style_2">
               9. EXPAND YOUR SONIC HORIZON
            </h3>
        </div>
        <div class="col-md-6">
            <img src="assets/images/emuse_13.png" style="
            width: 88%;">
        </div>
        <div class="col-md-6 space_top">
            <p><b>Raising Sound Awareness</b></p>
            <p>Our musical knowledge is learned, the product of long experience; maybe not years spent over playing an instrument, but a lifetime spent absorbing music from our environment by default and/or by choice.</p>
            <p><b>Once you hear something you can not “unhear” it.  Exploring music is at the same time a great way to expand our understanding of the world - historically, culturally and emotionally. We have been enchanted by music, paintings, crafts and  architecture of many cultures, from time immemorial. All the traditions had something to offer, all the tributaries contributed to the great river of time and culture – the river of humanity. By enhancing our capacity to consciously recognize, appreciate and connect with our vast universe of sounds, we can actively and positively transform our lives while creating personal and collective harmony.</b></p>
            <p><b>Use: </b>these selections when you read and learn about other cultures or different historical periods, when you travel to discover new countries, learn foreign language, prepare for business ventures in foreign cultures.</p>
            <p><b>Listening strategy:</b>As you listen mindfully, try to focus on melody, on rhythm, pay attention to lead instruments/voices then to one of the secondary (i.e. not the lead) instruments; stay open, notice your feelings, images.</p>
        </div>


        <div class="col-md-12">
            <h4>Human Capital is the Most Valuable Asse</h4>
            <b>EuMuse Music Program applicability in:</b>
        </div>
        <div class="col-md-6">
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Businesses</p>
            </div>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Schools</p>
            </div>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Kindergartens</p>
            </div>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Wellness centers</p>
            </div>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Clinics</p>
            </div>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Assisted living facilities</p>
            </div>
        </div>

        <div class="col-md-12 space_top">
           <b>Guiding thoughts of EuMuse Music Listening Program</b>
        </div>
        <div class="col-md-12">
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Music phenomenon is regarded as cognitive, cultural, social and aesthetic phenomenon rather than merely as a matter of acoustical brain stimulation</p>
            </div>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Music material is considered not as a static content to be mastered, but as personal, current and evolving.</p>
            </div>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Listening to music compositions, as an art form whose medium is sound and silence, with the understanding of psychological and physiological effects of major music elements e.g. melody, rhythm, harmony, pitch, timbre, texture and dynamics.</p>
            </div>
            
        </div>

        <div class="col-md-12 space_top">
            <b>Goal</b>
         </div>
         <div class="col-md-12">
             <div class="col-md-12 para_spacing">
                 <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">generate beneficial effects of music listening on subjective wellbeing and health(health defined as a state of complete physical, mental and social wellbeing)</p>
             </div>
             <div class="col-md-12 para_spacing">
                 <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">cultivate and develop conscious attention and its action in the body, mind and heart</p>
             </div>
             <div class="col-md-12 para_spacing">
                 <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">invigorate physical, mental and emotional rejuvenation</p>
             </div>
             
         </div>

         <div class="col-md-12 space_top">
            <b>By learning</b>
         </div>
         <div class="col-md-12">
             <div class="col-md-12 para_spacing">
                 <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">How and why to include mindful listening of music in every day's life</p>
             </div>
             <div class="col-md-12 para_spacing">
                 <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">How what we hear transforms our brains and lives</p>
             </div>
             <div class="col-md-12 para_spacing">
                 <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">How to creatively alter one's sonic environment</p>
             </div>
             <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">How to design and use the power of music in re-tuning and re-harmonizing our mind and body</p>
            </div>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">How to boost efficiency by optimizing what, when, where and how to listen</p>
            </div>
             
         </div>

    </div>
</div>
