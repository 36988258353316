<div id="myCarousel" class="carousel slide banner-2" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active"> <img class="first-slide img-fluid" src="assets/images/about_us_pic_header.jpeg" alt="First slide">
      <div class="container">
        <div class="carousel-caption banner1 text-left">
          <h2 class="wow fadeInUp">About</h2>
          <p class="breadcrumb-item"><a href="#">Home</a> / About</p>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="section-padding-ash">
  <div class="container">
    <div class="row">

      <div class="col-lg-6">
        <video autoplay loop controls muted name="media" width="100%" src="http://rainbomics.com/wp-content/uploads/2019/12/Anti-Aging.mp4">
          
        </video>
      </div>
      <div class="col-lg-6 border_left">
        <h2 class="heading">
          Karte Corporation
        </h2>
        <p>Life science is rapidly changing as big data and AI technologies are used in earnest.
          Also, omics data, which include the genome, transcriptome, and proteome, are rapidly growing as results of the development of sequencing technology and reduction of production costs.
          
          </p>
          <p>
            As meaningful patterns are found in omics data by AI technology and algorithms are made, the classification of responses patients have to diseases like cancer have become possible and research on new treatments is being conducted.
Karte leads at the front of this new paradigm with the development of customized AI solutions using omics and image data.
In addition, a cloud-based big data consulting service is provided.
          </p>
      </div>
     
     
    
    </div>
  </div>
</section>

