<div class="container container_spacing">
    <div class="row">
        <div class="col-md-12">
            <h2 style="color: #676767; border-bottom: 1px solid #b3b3b3; padding-bottom: 10px;"><span style="color: #3d8d61;"> Aging</span> App</h2>
        </div>
        <div class="col-md-6">
            <video loop controls muted name="media" width="100%" src="http://rainbomics.com/wp-content/uploads/2019/12/Anti-Aging.mp4"></video>
        </div>
        <div class="col-md-6">
            <h3>About this App</h3>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula,</p>
        </div>
        <div class="col-md-6 text-spacing wow fadeInUp">
            <h4>Nam quam nunc, blandit vel, luctus pulvinar, hendrerit</h4>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.</p>
            <img class="clip_img wow fadeInUp" src="assets/images/app_vector_1.png" data-wow-delay="0.7s">
        </div>
        <div class="col-md-6 screen_1 wow fadeInRight">
            <img src="assets/images/healthApp_ss_1.png">
            
        </div>
        <div class="col-md-6 screen_1 wow fadeInLeft">
            <img src="assets/images/healthApp_ss_2.png">
            
        </div>
        <div class="col-md-6 text-spacing wow fadeInUp">
            <h4>Nam quam nunc, blandit vel, luctus pulvinar, hendrerit</h4>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.</p>
            <img class="clip_img wow fadeInUp" src="assets/images/app_vector_2.png" data-wow-delay="0.7s">
        </div>
        <div class="col-md-6 text-spacing wow fadeInUp">
            <h4>Nam quam nunc, blandit vel, luctus pulvinar, hendrerit</h4>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.</p>
            <img class="clip_img wow fadeInUp" src="assets/images/app_vector_3.png" data-wow-delay="0.7s">
        </div>
        <div class="col-md-6 screen_1 wow fadeInRight">
            <img src="assets/images/healthApp_ss_3.png">
            
        </div>
        <div class="col-md-6 screen_1 wow fadeInLeft">
            <img src="assets/images/healthApp_ss_4.png">
            
        </div>
        <div class="col-md-6 text-spacing wow fadeInUp">
            <h4>Nam quam nunc, blandit vel, luctus pulvinar, hendrerit</h4>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.</p>
            <img class="clip_img wow fadeInUp" src="assets/images/app_vector_4.png" data-wow-delay="0.7s">
        </div>
       
    </div>
</div>
