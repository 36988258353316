import { PlatformComponent } from './../platform.component';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-iheathage',
  templateUrl: './iheathage.component.html',
  styleUrls: ['./iheathage.component.css']
})
export class IheathageComponent implements OnInit {

  public activeHealthage;
  public activeScreen;
  public activeProcedure;
  public activeReports;
  // tslint:disable-next-line:ban-types
  public text: String;
  

  constructor(private route: ActivatedRoute, private router: Router) {

    
    
    if (this.router.isActive('/platform/ihealthage/healthaging', true)) {
      console.log('Active');
      this.activeHealthage = true;
      this.text = '1';
      
    } else if (this.router.isActive('platform/ihealthage/screens', true)) {
      this.activeScreen = true;
      this.text = '2';
    } 
    else if (this.router.isActive('platform/ihealthage/procedure', true)) {
      this.activeProcedure = true;
      this.text = '3';
    } 
    else if (this.router.isActive('platform/ihealthage/reports', true)) {
      this.activeReports = true;
      this.text = '4';
    } 
      
    

   }

  ngOnInit(): void {
  }

  

  healthyAge(){
    this.router.navigate(['healthaging'], {relativeTo: this.route});
    this.activeHealthage = true;
    this.activeScreen = false;
    this.activeProcedure = false;
    this.activeReports = false;
    this.text = '1';
  }
  screen(){
    this.router.navigate(['screens'], {relativeTo: this.route});
    this.activeHealthage = false;
    this.activeScreen = true;
    this.activeProcedure = false;
    this.activeReports = false;
  }
  procedure(){
    this.router.navigate(['procedure'], {relativeTo: this.route});
    this.activeHealthage = false;
    this.activeScreen = false;
    this.activeProcedure = true;
    this.activeReports = false;

  }
  report(){
    this.router.navigate(['reports'], {relativeTo: this.route});
    this.activeHealthage = false;
    this.activeScreen = false;
    this.activeProcedure = false;
    this.activeReports = true;
  }

}
