import { Component, OnInit } from '@angular/core';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {


  form: FormGroup;
  firstName: FormControl = new FormControl('', [Validators.required]);
  lastName: FormControl = new FormControl('', [Validators.required]);
  phoneNumber: FormControl = new FormControl('', [Validators.required]);
  email: FormControl = new FormControl('', [Validators.required, Validators.email]);
  message: FormControl = new FormControl('', [Validators.required, Validators.maxLength(256)]);
  // tslint:disable-next-line:ban-types
  submitted: Boolean = false; // show and hide the success message
  // tslint:disable-next-line:ban-types
  required: Boolean = false;
  // tslint:disable-next-line:ban-types
  isLoading: Boolean = false; // disable the submit button if we're loading
  responseMessage: string; // the response message to show to the user

  constructor(private formBuilder: FormBuilder, private http: HttpClient, private ngxService: NgxUiLoaderService) {
    this.form = this.formBuilder.group({
      firstName: this.firstName,
      lastName: this.lastName,
      phoneNumber: this.phoneNumber,
      email: this.email,
      message: this.message
    });
  }


  // }
  faAddressCard = faAddressCard;
  faPhoneAlt = faPhoneAlt;
  faEnvelope = faEnvelope;
  ngOnInit(): void {
  }
  onSubmit() {
    if (this.form.dirty && this.form.valid) {
      this.required = false;
      this.form.disable(); // disable the form if it's valid to disable multiple submissions
      // tslint:disable-next-line:prefer-const
      let formData: any = new FormData();
      formData.append('firstName', this.form.get('firstName').value);
      formData.append('lastName', this.form.get('lastName').value);
      formData.append('phoneNumber', this.form.get('phoneNumber').value);
      formData.append('email', this.form.get('email').value);
      formData.append('message', this.form.get('message').value);
      this.isLoading = true; // sending the post request async so it's in progress
      this.submitted = false; // hide the response message on multiple submits
      this.ngxService.startBackgroundLoader('loader-01');
      // tslint:disable-next-line:max-line-length
      this.http.post('https://script.google.com/macros/s/AKfycbxw52hyrgXCV7NtgT8OOcY00G9Fldc9nGmmb726NDXKnnNyl9YY/exec', formData).subscribe(
        (response) => {
          // choose the response message
          // tslint:disable-next-line:no-string-literal
          if (response['result'] === 'success') {
            this.responseMessage = 'Thanks for the message! We ll get back to you soon!';
          } else {
            this.responseMessage = 'Oops! Something went wrong... Reload the page and try again.';
          }
          this.form.enable(); // re enable the form after a success
          this.submitted = true; // show the response message
          this.isLoading = false; // re enable the submit button
          console.log(response);
          this.ngxService.stopBackgroundLoader('loader-01');
        },
        (error) => {
          this.responseMessage = 'Oops! An error occurred... Reload the page and try again.';
          this.form.enable(); // re enable the form after a success
          this.submitted = true; // show the response message
          this.isLoading = false; // re enable the submit button
          console.log(error);
        }
      );
    }else{
      this.required = true;
    }
  }

}
