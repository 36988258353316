import { Component, OnInit } from '@angular/core';
import { faDna } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-procedure',
  templateUrl: './procedure.component.html',
  styleUrls: ['./procedure.component.css']
})
export class ProcedureComponent implements OnInit {

  constructor() { }

  faDna = faDna;

  ngOnInit(): void {
  }

}
