<nav class="navbar navbar-expand-md btco-hover-menu navbar-1 navSpace">
  <div class="container"> <a class="navbar-brand" [routerLink]="['']"><img src="assets/images/logo.png" class="img-fluid" alt="logo"></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation"> <span class="navbar-toggler-icon"></span> </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <li class="nav-item"><a class="nav-link" [routerLink]="['']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"> Karte Home</a></li>
        <li class="nav-item"> <a class="nav-link" [routerLink]="['about']" [routerLinkActive]="['active']">About Us</a> </li>
        <li class="nav-item"> <a class="nav-link" [routerLink]="['services']" [routerLinkActive]="['active']">Our Services</a> </li>
        <li class="nav-item dropdown"> <a class="nav-link dropdown-toggle" [routerLink]="['platform/ihealthage/healthaging']" [routerLinkActive]="['active']" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Our Platforms</a> 
          <ul class="dropdown-menu">
            <li ><a class="dropdown-item space_bottom" [routerLink]="['platform/ihealthage/healthaging']" [routerLinkActive]="['active']" (click)="ihealthTitile()">Karte Aging</a></li>
            <li><a class="dropdown-item space_bottom" [routerLink]="['platform/ihealthchain']" [routerLinkActive]="['active']">Karte Healthcare Blockchain</a></li>
            <li><a class="dropdown-item space_bottom" [routerLink]="['platform/ihealthgene']" [routerLinkActive]="['active']">Karte Genetic Knowledge Graph</a></li>
            <li><a class="dropdown-item space_bottom" [routerLink]="['platform/emuse_platform']" [routerLinkActive]="['active']">Marina De Moses - EUMUSE Platform</a></li>
            <li><a class="dropdown-item space_bottom" [routerLink]="['platform/pcthoc_rightgene_platform']" [routerLinkActive]="['active']">Metaboscreen - PcthOC RightGene Platform </a></li>
          </ul>
        </li>
        <li class="nav-item"> <a class="nav-link" [routerLink]="['ourscience']" [routerLinkActive]="['active']">Our Science</a> </li>
        <!-- <li class="nav-item dropdown"> <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Blog </a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" routerLink="/bloglist">Blog List</a></li>
            <li><a class="dropdown-item" routerLink="/blogdetails">Blog Details</a></li>
          </ul>
        </li> -->
        <li class="nav-item"> <a class="nav-link" [routerLink]="['contact']" [routerLinkActive]="['active']">Contact Us</a> </li>
      </ul>
     
    </div>
  </div>
</nav>
