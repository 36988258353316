<div class="container">
    <div class="row">
        <div class="col-md-12">
            <h2 class="plactform_heading"> PcthOC RightGene Platform</h2>
        </div>
        <div class="col-md-12">
            <img src="assets/images/path_oc_1.jpeg">
        </div>
        <div class="col-md-12">
            <img src="assets/images/path_oc-2.jpeg">
        </div>
    </div>
</div>
