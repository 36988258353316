<div class="container container_spacing">
    <div class="row">

        <div class="col-md-12">
            <h2 style="color: #676767; border-bottom: 1px solid #b3b3b3;"><span style="color: #3d8d61;"> Karte</span>AGE</h2>
            
        </div>
        <div class="col-md-6">
            <img src="assets/images/iheathage.png" style="
            width: 88%;">
        </div>
        <div class="col-md-6 dialogue_styling"> <h5 style="
            color: #5d5d5d;
            font-weight: 400;
            font-size: 25px;
        ">
        Harnessing the power of the genome for healthy aging</h5></div>
        <div class="col-md-6 ihealth_age_style"> 

            <p class="para_heading_style"><b>How old are you?</b></p>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">People age at different rates. Some “look” and “feel” older than their chronological age while other look younger than their chronological age.​</p>
            </div>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Our biological age is a better parameter of our health well being and life span than our chronological age. ​</p>
            </div>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">DNA methylation and other epigenetic factors are the software that programs the genome.​​</p>
            </div>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">A paradigm shift in the search for biological age markers occurred with the discovery of the “epigenetic clock” by Horvath, which is based on measurement of DNA methylation status at 500 strategic positions in DNA.​</p>
            </div>

        </div>
        <div class="col-md-6">
            <img src="assets/images/how_old_are_you.png">
        </div>
        <div class="col-md-6">
            <img src="assets/images/iheathTest.png">
        </div>
        <div class="col-md-6 ihealth_age_style"> 

            <p class="para_heading_style"><b>​How does the KarteAge test work?</b></p>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Order KarteAge test kit through Karte App.</p>
            </div>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">The kit will be sent to you by express. ​</p>
            </div>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Scan the unique barcode included in your kit.​​</p>
            </div>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Collect saliva into the collection tubes following the instruction.​</p>
            </div>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Sent the kit back in the provided envelop.​</p>
            </div>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Log in the app to view your test results.</p>
            </div>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Feed us with your health and lifestyle information to establish your personalized health ecosystem​</p>
            </div>

        </div>
        <div class="col-md-6 karte_age_style"> 

            <p class="para_heading_style"><b>Why do you need Health and Lifestyle Questionnaires in the app?</b></p>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">KarteAge tests make sense only within a dynamic life-long life style, environmental and health management system.​</p>
            </div>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">A personalized evaluation including intervention will be generated based on the health and lifestyle information you provided. ​ ​</p>
            </div>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Updates on your health and lifestyle parameters periodically to activate the life-long personalized analysis report​</p>
            </div>
            

        </div>
        <div class="col-md-6">
            <img src="assets/images/health_lifestyle_app.png">
        </div>

        <div class="col-md-6">
            <img src="assets/images/heath_data.png">
        </div>
        <div class="col-md-6 karte_age_style"> 

            <p class="para_heading_style"><b>How the data will be used?</b></p>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Continuously analyze karteAge test results using Artificial Intelligence (AI), general health parameters, personal interventions and its outcomes of multiple users using state of the art machine learning algorithms ​</p>
            </div>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Personalized analysis with some possible routes for improvement​​. ​</p>
            </div>
            <div class="col-md-12 para_spacing">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Coevolving of your well being and our “karteAge” learning environment​.​</p>
            </div>
            

        </div>

        <div class="col-md-12 your_profile_styling">
            <h3>Your Profile</h3>
        </div>

        <div class="col-md-6 profile_content">

            <div class="col-md-12 profile_content_spaceing">
                <fa-icon [icon]="faHeartbeat" class="dnaIconStyle"></fa-icon><p class="para_style font_size"><b>ID: </b> 000000001​</p>
                
            </div>
            <div class="col-md-12 profile_content_spaceing">
                <fa-icon [icon]="faHeartbeat" class="dnaIconStyle"></fa-icon><p class="para_style font_size"><b>Sex: </b> Male</p>
                
            </div>
            <div class="col-md-12 profile_content_spaceing">
                <fa-icon [icon]="faHeartbeat" class="dnaIconStyle"></fa-icon><p class="para_style font_size"><b>Age: </b> 58</p>
            </div>

        </div>

        <div class="col-md-6">
           <img src="assets/images/your_profile.png">
        </div>

        <div class="col-lg-12 your_profile_styling">
            <h3>Health & Lifestyle</h3>
        </div>

        <div class="col-lg-6 col-sm-12">

            <img src="assets/images/health_checkup.png">
            <img src="assets/images/health_checkup_2.png">
        </div>

        <div class="col-lg-6 col-sm-12 bar_data_section">

            <div class="row">
                <div class="col-md-3">
                    <p>Height(Ib)</p>
                </div>
                
                <div class="col-md-9">
                    <div class="health_height_data_bar_style">

                    </div>
                    <div class="row">
                        <div class="col-md-6 width_50">
                            <p class="font_size_data_value">50</p>
                        </div>
                        <div class="col-md-6 width_50">
                            <p class="font_size_data_value end_value">450</p>
                        </div>
                    </div>

                </div>
                
            </div>
            <div class="row">
                <div class="col-md-3">
                    <p>Weight(cm)</p>
                </div>
                
                <div class="col-md-9">
                    <div class="health_height_data_bar_style">

                    </div>
                    <div class="row">
                        <div class="col-md-6 width_50">
                            <p class="font_size_data_value">1</p>
                        </div>
                        <div class="col-md-6 width_50">
                            <p class="font_size_data_value end_value">300</p>
                        </div>
                    </div>

                </div>
                
            </div>
            <div class="row">
                <div class="col-md-3">
                    <p>BMI</p>
                </div>
                
                <div class="col-md-9">
                    <div class="row bmi_data_bar_content">
                        <div class="col-md-3 health_lowest_point width_25">

                        </div>
                        <div class="col-md-3 health_mid_1_point width_25">

                        </div>
                        <div class="col-md-3 health_mid_2_point width_25">

                        </div>
                        <div class="col-md-3 health_extreme_point width_25">

                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-md-2 width_20">
                            <p class="font_size_data_value">5</p>
                        </div>
                        <div class="col-md-2 width_20">
                            <p class="font_size_data_value">18.5</p>
                        </div>
                        <div class="col-md-2 width_20">
                            <p class="font_size_data_value end_value">300</p>
                        </div>
                        <div class="offset-md-1 width_null">
                            
                        </div>
                        <div class="col-md-2 width_20">
                            <p class="font_size_data_value end_value">300</p>
                        </div>
                        <div class="offset-md-1 width_null">
                            
                        </div>
                        <div class="col-md-2 width_20">
                            <p class="font_size_data_value end_value">100</p>
                        </div>
                    </div>

                </div>
                
            </div>
            <div class="row">
                <div class="col-md-3">
                    <p>Heart rate (beats/min)</p>
                </div>
                
                <div class="col-md-9">
                    <div class="row bmi_data_bar_content">
                        
                        <div class="col-md-4 health_mid_1_point radius_corner width_33">

                        </div>
                        <div class="col-md-4 health_mid_2_point width_33">

                        </div>
                        <div class="col-md-4 health_extreme_point width_33">

                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-md-4 width_33">
                            <p class="font_size_data_value">30</p>
                        </div>
                        <div class="col-md-4 width_33">
                            <p class="font_size_data_value">100</p>
                        </div>
                        <div class="col-md-4 width_33">
                            <p class="font_size_data_value end_value">200</p>
                        </div>
                        
                    </div>

                </div>
                
            </div>
            <div class="row">
                <div class="col-md-12">
                    <p><b>Blood pressure (mmHG)</b></p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <p>Systolic BP</p>
                </div>
                
                <div class="col-md-9">
                    <div class="row bmi_data_bar_content">
                        <div class="col-md-3 health_mid_1_point radius_corner width_25">

                        </div>
                        <div class="col-md-3 health_mid_3_point width_25">

                        </div>
                        <div class="col-md-3 health_mid_2_point width_25">

                        </div>
                        <div class="col-md-3 health_extreme_point width_25">

                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-md-2 width_20">
                            <p class="font_size_data_value">40</p>
                        </div>
                        <div class="col-md-2 width_20">
                            <p class="font_size_data_value">120</p>
                        </div>
                        <div class="col-md-2 width_20">
                            <p class="font_size_data_value end_value">140</p>
                        </div>
                        <div class="offset-md-1 width_null">
                            
                        </div>
                        <div class="col-md-2 width_20">
                            <p class="font_size_data_value end_value">180</p>
                        </div>
                        <div class="offset-md-1 width_null">
                            
                        </div>
                        <div class="col-md-2 width_20">
                            <p class="font_size_data_value end_value">220</p>
                        </div>
                    </div>

                </div>
                
            </div>
            <div class="row">
                <div class="col-md-3">
                    <p>Diastolic BP</p>
                </div>
                
                <div class="col-md-9">
                    <div class="row bmi_data_bar_content">
                        <div class="col-md-3 health_mid_1_point radius_corner width_25">

                        </div>
                        <div class="col-md-3 health_mid_3_point width_25">

                        </div>
                        <div class="col-md-3 health_mid_2_point width_25">

                        </div>
                        <div class="col-md-3 health_extreme_point width_25">

                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-md-2 width_20">
                            <p class="font_size_data_value">40</p>
                        </div>
                        <div class="col-md-2 width_20">
                            <p class="font_size_data_value">80</p>
                        </div>
                        <div class="col-md-2 width_20">
                            <p class="font_size_data_value end_value">90</p>
                        </div>
                        <div class="offset-md-1 width_null">
                            
                        </div>
                        <div class="col-md-2 width_20">
                            <p class="font_size_data_value end_value">120</p>
                        </div>
                        <div class="offset-md-1 width_null">
                            
                        </div>
                        <div class="col-md-2 width_20">
                            <p class="font_size_data_value end_value">220</p>
                        </div>
                    </div>

                </div>
                
            </div>
            <div class="row space_seperation">
                <div class="col-md-3">
                    <p>Cholesterol (mg/dL)</p>
                </div>
                
                <div class="col-md-9">
                    <div class="row bmi_data_bar_content">
                        
                        <div class="col-md-4 health_mid_1_point radius_corner width_33">

                        </div>
                        <div class="col-md-4 health_mid_2_point width_33">

                        </div>
                        <div class="col-md-4 health_extreme_point width_33">

                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-md-3 width_25">
                            <p class="font_size_data_value">100</p>
                        </div>
                        <div class="col-md-3 width_25">
                            <p class="font_size_data_value">200</p>
                        </div>
                        <div class="col-md-3 width_25">
                            <p class="font_size_data_value end_value">240</p>
                        </div>
                        <div class="col-md-3 width_25">
                            <p class="font_size_data_value end_value">300</p>
                        </div>
                        
                    </div>

                </div>
                
            </div>
            <div class="row">
                <div class="col-md-3">
                    <p>Sleep (hour/day)</p>
                </div>
                
                <div class="col-md-9">
                    <div class="health_height_data_bar_style">

                    </div>
                    <div class="row">
                        <div class="col-md-6 width_50">
                            <p class="font_size_data_value">0</p>
                        </div>
                        <div class="col-md-6 width_50">
                            <p class="font_size_data_value end_value">24</p>
                        </div>
                    </div>

                </div>
                
            </div>
            <div class="row">
                <div class="col-md-3">
                    <p>Meditation (hour/day)</p>
                </div>
                
                <div class="col-md-9">
                    <div class="health_height_data_bar_style">

                    </div>
                    <div class="row">
                        <div class="col-sm-3 col-md-6 width_50">
                            <p class="font_size_data_value">0</p>
                        </div>
                        <div class="col-sm-3 col-md-6 width_50">
                            <p class="font_size_data_value end_value">24</p>
                        </div>
                    </div>

                </div>
                
            </div>
            <div class="row">
                <div class="col-md-3">
                    <p>Sex Life</p>
                </div>
                
                <div class="col-md-9">
                    <div class="health_height_data_bar_style">

                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <p class="font_size_data_value"></p>
                        </div>
                        <div class="col-md-6">
                            <p class="font_size_data_value end_value"></p>
                        </div>
                    </div>

                </div>
                
            </div>
            <div class="row">
                <div class="col-md-3">
                    <p>Smoking (stick/day)</p>
                </div>
                
                <div class="col-md-9">
                    <div class="row bmi_data_bar_content">
                        <div class="col-md-3 health_mid_1_point radius_corner width_25">

                        </div>
                        <div class="col-md-3 health_mid_3_point width_25">

                        </div>
                        <div class="col-md-3 health_mid_2_point width_25">

                        </div>
                        <div class="col-md-3 health_extreme_point width_25">

                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-md-2 width_20">
                            <p class="font_size_data_value">0</p>
                        </div>
                        <div class="col-md-2 width_20">
                            <p class="font_size_data_value">8</p>
                        </div>
                        <div class="col-md-2 width_20">
                            <p class="font_size_data_value end_value">15</p>
                        </div>
                        <div class="offset-md-1 width_null">
                            
                        </div>
                        <div class="col-md-2 width_20">
                            <p class="font_size_data_value end_value">25</p>
                        </div>
                        <div class="offset-md-1 width_null">
                            
                        </div>
                        <div class="col-md-2 width_20">
                            <p class="font_size_data_value end_value">50</p>
                        </div>
                    </div>

                </div>
                
            </div>
            <div class="row">
                <div class="col-md-3">
                    <p>Alcohol (bottle/day)</p>
                </div>
                
                <div class="col-md-9">
                    <div class="row bmi_data_bar_content">
                        <div class="col-md-3 health_mid_1_point radius_corner width_25">

                        </div>
                        <div class="col-md-3 health_mid_3_point width_25">

                        </div>
                        <div class="col-md-3 health_mid_2_point width_25">

                        </div>
                        <div class="col-md-3 health_extreme_point width_25">

                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-md-2 width_20">
                            <p class="font_size_data_value">0</p>
                        </div>
                        <div class="col-md-2 width_20">
                            <p class="font_size_data_value">1</p>
                        </div>
                        <div class="col-md-2 width_20">
                            <p class="font_size_data_value end_value">2</p>
                        </div>
                        <div class="offset-md-1 width_null">
                            
                        </div>
                        <div class="col-md-2 width_20">
                            <p class="font_size_data_value end_value">5</p>
                        </div>
                        <div class="offset-md-1 width_null">
                            
                        </div>
                        <div class="col-md-2 width_20">
                            <p class="font_size_data_value end_value">10</p>
                        </div>
                    </div>

                </div>
                
            </div>
            <div class="row">
                <div class="col-md-3">
                    <p>Vitamin (mg/day)</p>
                </div>
                
                <div class="col-md-9">
                    <div class="health_height_data_bar_style">

                    </div>
                    <div class="row">
                        <div class="col-md-6 width_50">
                            <p class="font_size_data_value">0</p>
                        </div>
                        <div class="col-md-6 width_50">
                            <p class="font_size_data_value end_value">20</p>
                        </div>
                    </div>

                </div>
                
            </div>
            

        </div>
        <div class="col-md-12">
            <div style="display: block;">
                <canvas baseChart
                  [datasets]="bubbleChartData"
                  [options]="bubbleChartOptions"
                  
                  [legend]="bubbleChartLegend"
                  [chartType]="bubbleChartType">
                </canvas>
              </div>
        </div>
        
        
        </div>
        
    
    

</div>


