import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pcthocplatform',
  templateUrl: './pcthocplatform.component.html',
  styleUrls: ['./pcthocplatform.component.css']
})
export class PcthocplatformComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
