<div class="container">
 
    <div class="row">

        <div class="col-md-12">
            <h2 class="plactform_heading"> Karte Aging</h2>
        </div>

        <div class="col-md-3 space_nav" (click)='healthyAge()'>
            
            <div class="tab_button" [class.active]="activeHealthage">
                Karte Aging
            </div>
        
        </div>
        <div class="col-md-3 space_nav" (click)='screen()'> 
            <div class="tab_button" [class.active]="activeScreen">
                Aging App
            </div>
        </div>
        <div class="col-md-3 space_nav" (click)='procedure()'>
            <div class="tab_button" [class.active]="activeProcedure">
                Procedure
            </div>
        </div>
        <div class="col-md-3 space_navspace_nav" (click)='report()'>
            <div class="tab_button" [class.active]="activeReports">
                Aging Report
            </div>
        </div>
    </div>
    

    <router-outlet></router-outlet>

</div>
