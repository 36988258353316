<div id="myCarousel" class="carousel slide banner-2" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active"> <img class="first-slide img-fluid" src="assets/images/our_platform_pic_header.jpeg" alt="First slide">
        <div class="container">
          <div class="carousel-caption banner1 text-left">
            <h2 class="wow fadeInUp">Our Platforms</h2>
            <p class="breadcrumb-item"><a href="#">Home</a> / Platforms</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section class="section-padding-ash padding_space">
    <div class="container">
      <div class="row">

      
  
       
       <div class="col-md-12"> 

        <router-outlet></router-outlet>


       </div>
       
       
       
      
      </div>
    </div>
  </section>
