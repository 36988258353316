<div class="container">
    <div class="row">
        <div class="col-12">
            <h2 class="plactform_heading">Karte Genetic Knowledge Graph</h2>
        </div>
        <div class="col-md-12">
            <img src="assets/images/coming_soon_vector.png" width="90%">
        </div>
       

        
    </div>
</div>
