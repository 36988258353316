<div class="container">
    <div class="row">
        <div class="col-md-12">
            <h2 class="plactform_heading">Karte Healthcare Blockchain</h2>
        </div>
        <div class="col-md-12">
            <h4>
                Blockchain Application Platform in Biotech Laboratory for Error Detection,Process Immutability and Audit
            </h4>
        </div>
        <div class="col-md-6">

            <p>
                A Blockchain is a ledger of records arranged in data batches called blocks that use cryptographic
validation to link themselves together. Put simply, each block references and identifies the previous
block by a hashing function, forming an unbroken chain, hence the name.
            </p>

            <p>
                Having multiple individualized records that captures each laboratory process&#39; steps and records this unit
process information is prone to error, fraud and inefficiencies. Our goal is to capture each laboratory
resource transaction end-to-end and reduce those vulnerabilities.
            </p>
            <p>
                Immutability is currently one of the major issues at the heart of the Blockchain. Blockchains are an
"append- only" system, which means we can only add data to it, we cannot take it away or change it in
any way. Changes to the data (if any) have to be appended immutably to the Blockchain as separate line
item (perhaps in a different Block) in the same way as in the ledger. That means that all the information
on a Blockchain is essentially permanent and immutable.
            </p>
        </div>
        <div class="col-md-6">
            <img src="assets/images/ihealthChain_img_1.png" style="
            width: 88%;">
        </div>
        <div class="col-md-6">
            <img src="assets/images/blockchain-1.jpeg" style="
            width: 88%;">
        </div>
        <div class="col-md-6">
            <img src="assets/images/blockchain-2.jpeg" style="
            width: 88%;">
        </div>
        <div class="col-md-12 space_margin">
            <p>
                <b>Laboratory Automation and Process Trust Innovation with Blockchain</b>
            </p>
        </div>
        <div class="col-md-6">
            <p>Lab automation using Robotics technology offers benefits beyond just allowing scientists to carry out
                more parallel tasks faster. With Automation, the results of research can be trusted as they are less error-
                prone.</p>
                <p>Our platform uses a Resource Blockchain (RB) that will track a chronology of events associated with the
                    corresponding laboratory automation resource (eg. Robot arm). From this event chronology, any robot
                    can determine if another robot has established a synchronization primitive or has earlier priority to
                    establish a synchronization primitive for accessing the resource associated with the Blockchain.</p>
                <p>In our iHealthChain embodiment, a robot will write to the Blockchain using a private key that is only
                    known to the robot. The robot can the append the Blockchain by retrieving a prior hash value and
                    hashing the retrieved value with the robot&#39;s own private key. Other robots can use a public key to go
                    back in the Blockchain chronology and determine one or more robots with current access rights or
                    priority rights to the resource. This ensures the right chronology even if process steps vary in their
                    timing in each process cycle.</p>
                    <p>The above embodiment will be implemented in a Genetics laboratory setting with respect to robots that
                        move across a common plane within one laboratory. These include any robots with wheels, legs, or
                        other means of powered or motorized movement. Other components of the robots include a power
                        source, such as a battery, to provide power for motors or engines that move the wheels, legs, or other
                        means of movement. The components can further include wireless or wired network connectivity, a
                        camera or scanner to image the markers, and a processor to decode the markers, establish the locks,
                        control movements of the robots, and communicate with the coordinator or other robots.</p>
        </div>
        <div class="col-md-6">
            <img src="assets/images/blockchain-3.png" style="
            width: 88%;">
        </div>
        <div class="col-md-12 space_margin">
            <p>
                <b>Smart Contracts for Error Reduction and fault-Tolerance in Bio-tech Laboratory process Automation</b>
            </p>
        </div>
        <div class="col-md-6">
            <img src="assets/images/blockchain-4.png" style="
            width: 88%;">
        </div>
        <div class="col-md-6">
            <p>A smart contract, also sometime known as a cryptocontract, is a computer program that directly
                controls the transfer of digital instructions or assets between parties under certain conditions. A smart
                
                contract not only defines the rules and penalties around an agreement in the same way that a
                traditional contract does, but it can also automatically enforce those obligations. It does this by taking in
                information as input, assigning value to that input through the rules set out in the contract, and
                executing the actions required by those contractual clauses – for example, determining whether an
                asset (DNA sample with a barcode) should go from one marked well of 96-well plate to a certain marked
                well of another 96-well plate or returned to the a certain rack from whom the asset originated. These
                contracts are stored on Blockchain technology. Blockchain is ideal for storing smart contracts because of
                the technology&#39;s security and immutability.</p>
        </div>
        <div class="col-md-6">
            <p>Our innovative method for establishing the synchronization primitive further comprises of Smart-
                Contracts for ensuring that the particular robot is:</p>
                <div class="col-12 para_spacing wow fadeInLeft">
                    <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Generating the unique identifier using a private key of the particular robot and</p>
                </div>
                <div class="col-12 para_spacing wow fadeInLeft">
                    <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Writing said unique identifier to a Blockchain associated with the first resource.​</p>
                </div>
                <div class="col-12 para_spacing wow fadeInLeft">
                    <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Determining at least a first resource from the plurality of resources required by the
                        particular robot for completion of said task;​</p>
                </div>
                <div class="col-12 para_spacing wow fadeInLeft">
                    <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Determining a path from a current position of the particular robot to a destination
                        associated with the task;​</p>
                </div>
                <div class="col-12 para_spacing wow fadeInLeft">
                    <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Establishing by the particular robot, a first synchronization primitive on the first resource
                        prior to the particular robot accessing the first resource, wherein said establishing provides
                        the particular robot exclusive access to the first resource and prevents other robots from
                        the plurality of robots from accessing the first resource while said first synchronization
                        primitive of the particular robot is established, wherein establishing the synchronization
                        primitive comprises associating a unique identifier of the particular robot to the first
                        resource;</p>
                </div>
                <div class="col-12 para_spacing wow fadeInLeft">
                    <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Avoiding fixed obstacles situated on the robot&#39;s trajectory and whose position is not known,</p>
                </div>
                <div class="col-12 para_spacing wow fadeInLeft">
                    <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Reaching a goal,</p>
                </div>
                <div class="col-12 para_spacing wow fadeInLeft">
                    <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Cooperating with other robots for concerted modifications of high-risk trajectories,</p>
                </div>
                <div class="col-12 para_spacing wow fadeInLeft">
                    <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Abiding by an avoidance protocol, compatible with the corresponding human behavior
                        (some sort of common human-robot &quot;highway code&quot;).​</p>
                </div>
                <div class="col-md-12">
                    <p><b>To these basic tasks, we have added two constraints for a realistic hardware implementation:</b></p>
                    <div class="col-12 para_spacing wow fadeInLeft">
                        <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Minimum information transfer between the robots,</p>
                    </div>
                    <div class="col-12 para_spacing wow fadeInLeft">
                        <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Real-Time Machine Learning methods.</p>
                    </div>
                    
                    
                </div>
                

        </div>
        <div class="col-md-6">
            <img src="assets/images/blockchain-5.png" style="
            width: 88%;">
            <img src="assets/images/blockchain-6.png" style="
            width: 88%; margin-top: 40px;">
        </div>
        <div class="col-md-12 space_margin">
            <p><b>Blockchain Implementation Details for BioTech Laboratory Automation</b></p>
        </div>
        <div class="col-md-6">
            <img src="assets/images/blockchain-7.png" style="
            width: 88%;">
        </div>
        <div class="col-md-6">
            <img src="assets/images/blockchain-8.jpeg" style="
            width: 100%;">
        </div>
    </div>
</div>
