import { Component, OnInit, AfterViewInit } from '@angular/core';
import { faDna, faHeartbeat } from '@fortawesome/free-solid-svg-icons';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Color } from 'ng2-charts';

@Component({
  selector: 'app-health-age',
  templateUrl: './health-age.component.html',
  styleUrls: ['./health-age.component.css']
})
export class HealthAgeComponent implements OnInit {

  public bubbleChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        ticks: {
          min: 0,
          max: 80,
        }
      }],
      yAxes: [{
        ticks: {
          min: 0,
          max: 70,
        }
      }]
    }
  };
  public bubbleChartType: ChartType = 'bubble';
  public bubbleChartLegend = true;
  

  public bubbleChartData: ChartDataSets[] = [
    {
      data: [
        { x: 10, y: 10, r: 10 },
        { x: 15, y: 5, r: 15 },
        { x: 26, y: 12, r: 23 },
        { x: 7, y: 8, r: 8 },
      ],
      label: ' Population whose biological age are older than chronological age',
    },
  ];
  options: {
    responsive: true;
    maintainAspectRatio: false;
  };

  constructor() { }
  faDna = faDna;
  faHeartbeat = faHeartbeat;

  ngOnInit(): void {
  }
  

}
