import { Component, OnInit } from '@angular/core';
import { faDna, faHeartbeat } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-ihealthchain',
  templateUrl: './ihealthchain.component.html',
  styleUrls: ['./ihealthchain.component.css']
})
export class IhealthchainComponent implements OnInit {

  constructor() { }
  faDna = faDna;
  faHeartbeat = faHeartbeat;

  ngOnInit(): void {
  }

}
