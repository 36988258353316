<div id="myCarousel" class="carousel slide banner-2" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active"> <img class="first-slide img-fluid" src="assets/images/contact_pic_header.jpeg" alt="First slide">
      <div class="container">
        <div class="carousel-caption banner1 text-left">
          <h2 class="wow fadeInUp">Contact</h2>
          <p class="breadcrumb-item"><a href="#">Home</a> / Contact</p>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="section-padding-ash">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="contact-left">
          <h4>Submit Your Message</h4>
          <p>Established fact that a reader will be distracted by the readable content</p>
          <div class="form-cont">
            <form [formGroup]="form" method="post" (ngSubmit)="onSubmit()">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <input [formControl]="firstName" type="text" class="form-control" placeholder="First Name *" required>
                    <div class="required_alert_text" *ngIf="(firstName.touched && firstName.hasError('required')) && required">Required</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input [formControl]="lastName" type="text" class="form-control" placeholder="Last Name *" required>
                    <div class="required_alert_text" *ngIf="lastName.touched && lastName.hasError('required')">Required</div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <input [formControl]="phoneNumber" type="tel" class="form-control" placeholder="Phone *">
                    <div class="required_alert_text" *ngIf="phoneNumber.touched && phoneNumber.hasError('required')">Required</div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <input [formControl]="email" class="form-control" placeholder="Email *" required>
                    <div class="required_alert_text" *ngIf="email.touched && email.hasError('required')">Required</div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <textarea [formControl]="message" rows="5" class="form-control" placeholder="Your Message *" required></textarea>
                    <div class="required_alert_text" *ngIf="message.touched && message.hasError('required')">Required</div>
                  </div>
                </div>
                <div class="col-md-12">
                  <button [ngClass]="isLoading? 'invisible' : 'visible'" type="submit" class="btn btn-default  wow tada">Submit</button>
                  <ngx-ui-loader [loaderId]="'loader-01'"></ngx-ui-loader>
                </div>
                <div [ngClass]="!submitted? 'invisible' : 'visible'" class="thankyou_message">
                  <!-- You can customize the thankyou message by editing the code below -->
                  <p>{{responseMessage}}
                  <p>
                 </div>
              </div>
            </form>
            
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="contact-right">
          <h4>Contact Info</h4>
          <p>Share Your Contact Info with Others</p>
          <div class="contact-info">
            <div class="row">
              <div class="col-lg-6">
                <div class="contact-info-item">
                  <div class="icon-cont"><fa-icon [icon]="faAddressCard" class="dnaIconStyle"></fa-icon></div>
                  <div class="text-cont">
                    <h6>Address:</h6>
                    <p><b>Karte Corporation</b></p>
                      <p>Unit 19, 8/F, Tai Tak Industrial Building, 2-12 Kwai Fat Road, Kwai Chung, New Territories, Hong Kong, China</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="contact-info-item">
                  <div class="icon-cont"><fa-icon [icon]="faPhoneAlt" class="dnaIconStyle"></fa-icon></div>
                  <div class="text-cont">
                    <h6>Phone:</h6>
                    <p>+852 6609 9885 (Hong Kong)</p>
                    <p>+1 407 768 3838 (USA)</p>
                  </div>
                </div>
              </div>
              
              <div class="col-lg-6">
                <div class="contact-info-item">
                  <div class="icon-cont"><fa-icon [icon]="faEnvelope" class="dnaIconStyle"></fa-icon></div>
                  <div class="text-cont">
                    <h6>Email ID:</h6>
                    <p>info@karte.com.hk</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
              	<div class="contact-map">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3689.682564398244!2d114.13240520251874!3d22.365611792233686!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3403f89a3b49c8b1%3A0x7f0560dbf24acb00!2sTai%20Tak%20Industrial%20Building%2C%202-12%20Kwai%20Fat%20Rd%2C%20Kwai%20Chung%2C%20Hong%20Kong!5e0!3m2!1sen!2sin!4v1617777285897!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen=""></iframe>
            </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <script data-cfasync="false" type="text/javascript" src="assets/js/form-submission-handler.js"></script>
</section>