<div class="container container_spacing">
    <div class="row">
        <div class="col-md-12">
            <h2 style="color: #676767; border-bottom: 1px solid #b3b3b3; padding-bottom: 10px;"><span style="color: #3d8d61;"> Aging Report</span></h2>
            
        </div>
        <div class="col-md-6 profile_content wow fadeInLeft"> 
            <div class="col-12 profile_content_spaceing">
                <fa-icon [icon]="faHeartbeat" class="dnaIconStyle"></fa-icon><p class="para_style font_size"><b>Name: </b> Peter​</p>
                
            </div>
            <div class="col-12 profile_content_spaceing">
                <fa-icon [icon]="faHeartbeat" class="dnaIconStyle"></fa-icon><p class="para_style font_size"><b>Customer ID: </b> bas000</p>
                
            </div>
            <div class="col-12 profile_content_spaceing">
                <fa-icon [icon]="faHeartbeat" class="dnaIconStyle"></fa-icon><p class="para_style font_size"><b>Report Date: </b> 9/26/2018</p>
            </div>
        </div>
        <div class="col-md-6 wow fadeInRight"> 
            <img src="assets/images/report_vector_1.png" style="width: 90%;">
        </div>
        <div class="col-md-12">
            <h3>What is the biological age?</h3>
        </div>
        <div class="col-md-12 wow fadeInUp">
            <p>Aging is a slow, time-dependent decline of functional capabilities of a person or an organ.
                Chronological age, which is the calendar time that has passed since birth, is in fact an
                imperfect surrogate measure of aging process. Numerous studies have been performed
                to find out the biomarkers that can accurately and rapidly predict the function capability
                of a person and how it changes with age.</p>
            <p>
                With the completion of Human Genome project, through analysing millions of datasets,
biological markers on the DNA were seen to change with age and the organ capabilities.
            </p>
            <p>
                DNA methylation is a biological process that modifies DNA and alters its activity without
changing its sequence. If you consider DNA as the hardware, DNA methylation is the
software that programs DNA and guides its function. A pile of literature have described
the impacts of chronological age on DNA methylation levels <sup>1,2,3,4,5</sup>

. The DNA methylation

age is thus calculated based on DNA methylation state<sup>6,7</sup>
.
            </p>
            <p>In most individuals, DNA methylation ages corresponds to their chronological age, while
                in some others, the DNA methylation age might be accelerated (older than the
                chronological age) or slowed down (younger than the chronological age). Accelerated
                DNA methylation age has been demonstrated to be associated with neuropathology in
                elderly individuals <sup>8,9</sup>
                
                , the risk of developing some types of cancer<sup>10,11,12</sup> and even
                
                death<sup>13,14</sup>
                . Therefore, the DNA methylation age is a promising biological age predictor.</p>

        </div>
        <div class="col-md-12 wow fadeInUp">
            <img src="assets/images/report_vector_2.png" style="width: 90%;">
        </div>
        <div class="col-md-12">
            <h3>How to predict biological age?</h3>
        </div>
        <div class="col-md-12 biological_aging_space wow fadeInUp">
            <img src="assets/images/report_img_1.jpeg" style="width: 90%; border-radius: 11px;">

        </div>
        <div class="col-md-6 age_spacing wow fadeInLeft">
        <div class="col-12 profile_content_spaceing">
            <fa-icon [icon]="faHeartbeat" class="dnaIconStyle"></fa-icon><p class="para_style font_size"><b>Your chronological age: </b> 42.3</p>
            
        </div>
        <div class="col-12 profile_content_spaceing">
            <fa-icon [icon]="faHeartbeat" class="dnaIconStyle"></fa-icon><p class="para_style font_size"><b>Your biological age: </b> 59.9</p>
            
        </div>
    </div>
    <div class="col-md-6 wow fadeInRight">
        <img src="assets/images/report_img_2.jpeg" style="width: 90%; border-radius: 11px;">
        <p class="key_spacing">Key:</p>
        <fa-icon [icon]="faCircle" class="key_1"></fa-icon><p class="para_style font_size">Biological age > Chronological age</p><br>
        <fa-icon [icon]="faCircle" class="key_2"></fa-icon><p class="para_style font_size">Biological age = Chronological age</p><br>
        <fa-icon [icon]="faCircle" class="key_3"></fa-icon><p class="para_style font_size">Biological age < Chronological age</p><br>
        <fa-icon [icon]="faCircle" class="key_4"></fa-icon><p class="para_style font_size">Your result</p><br>

    </div>
        <div class="col-md-12 result_spacing">
            <h3>Result Interpretation</h3>
        </div>
        <div class="col-md-12">
            <p style="color: rgb(190, 1, 1);">Biological age > Chronological age</p>
            <p>Alert! Your organs age faster than expected on the basis of chronological age (positive
                biological age acceleration). You are recommended to further assess your methylation
                homeostasis through genetic and metabolic tests. With our proprietary AI technology, we can
                derive personalized formula of supplements to strengthen your methylation homeostasis. It
                could ameliorate the acceleration of biological age, or even revert the DNA methylation clock.
                You are suggested to measure your biological age three months again after the intervention
                to assess the efficacy of intervention in slowing, halting or even reversing the biological age.</p>
        </div>
    </div>
</div>
