import { Component, OnInit } from '@angular/core';
import { faDna } from '@fortawesome/free-solid-svg-icons';
declare var $: any;

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {

  constructor() { }
  faDna = faDna;

  ngOnInit(): void {
$.getScript('../assets/js/custom.js');
  }

}
