<div class="VideoContainer"> 
  <video autoplay loop name="media" width="100%">
    <source src="https://web.govitatech.com/wp-content/uploads/2019/05/new_video_header_v3.2_1080.webm" type="video/webm">
  </video>
  
    </div>

<div id="myCarousel" class="carousel slide banner-1" data-ride="carousel">
  <!-- <ol class="carousel-indicators">
    <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
    <li data-target="#myCarousel" data-slide-to="1"></li>
    <li data-target="#myCarousel" data-slide-to="2"></li>
  </ol> -->
  <div class="carousel-inner">
    <div class="carousel-item active"> <img class="first-slide" src="assets/images/banner-1.png" alt="First slide">
      <div class="container">
        <div class="carousel-caption banner1 text-left">
          <h2 class="wow fadeInUp">Artificial intelligence (AI) driving Innovation in Bioscience</h2>
          <h6>A Complete Genetic Evaluation and Customized anti-aging Solution</h6>
          <p class="hidden-sm-down"><a class="btn btn-md btn_hover_carasol" routerLink="/services" role="button">Learn More</a></p>
        </div>
      </div>
    </div>
    <div class="carousel-item"> <img class="second-slide" src="assets/images/banner-2.png" alt="Second slide">
      <div class="container">
        <div class="carousel-caption banner1">
          <h2 class="wow fadeInUp">We Provide Right Solutions for Your Lasting Health</h2>
          <h6>Backed by Data Science and Machine Learning</h6>
          <p class="hidden-sm-down"><a class="btn btn-md btn_hover_carasol" href="#featureProduct" role="button">Learn more</a></p>
        </div>
      </div>
    </div>
    <div class="carousel-item"> <img class="third-slide" src="assets/images/banner-3.png" alt="Third slide">
      <div class="container">
        <div class="carousel-caption banner1 text-right">
          <h2 class="wow fadeInUp">GENETIC TESTS & INTERVENTIONS</h2>
          <h6>Methylation tests the polymorphism in key methylation cycle genes such as AHCY, BHMT, CBS, COMT, GNMT, MAT1A, MTHFR, MTRR, PEMT, SHMT and VDR/Taq. </h6>
          <p class="hidden-sm-down"><a class="btn btn-md btn_hover_carasol" href="#featureProduct" role="button">Learn More</a></p>
        </div>
      </div>
    </div>
  </div>
  <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev"> <span class="carousel-control-prev-icon" aria-hidden="true"></span> <span class="sr-only">Previous</span> </a> <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next"> <span class="carousel-control-next-icon" aria-hidden="true"></span> <span class="sr-only">Next</span> </a> </div>
  <section class="section-padding-about">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-lg-offset-right-6">
          <div class="text-left">
            <h2 class="about_header_styling wow fadeInUp">Who We Are</h2>
            <p class="about_description wow fadeInUp">Karte Corporation in association with Govita Tech was founded by leaders in life science R&D and Artificial Intelligence (AI) with the ultimate goal of becoming a world-class technology driven company focusing on the development and analysis of molecular biomarkers, associating test data with individual phenotype, with clinicians as partners providing personalized interventions to improve health, delay aging and enhance overall quality of life.</p>
            <p class="hidden-sm-down wow fadeInUp"><a class="btn btn-md btn-default about_us_read_more_button" routerLink="/about" role="button">Read More</a></p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-lg-offset-right-6">
            <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 wow fadeInUp">
                    <div class="counter-cont">
                        <h4><span class="numscroller" data-min="1" data-max="10" data-delay="5" data-increment="10">10</span>+</h4>
                          <h6>Clients</h6>
                      </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 wow fadeInUp">
                    <div class="counter-cont">
                        <h4><span class="numscroller" data-min="1" data-max="25" data-delay="5" data-increment="10">25</span>+</h4>
                          <h6>Projects</h6>
                      </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 wow fadeInUp">
                    <div class="counter-cont">
                        <h4><span class="numscroller" data-min="1" data-max="25" data-delay="5" data-increment="10">25</span>+</h4>
                          <h6>Products</h6>
                      </div>
                  </div>
              </div>    
          </div>
      </div>
    </div>
  </section>  
<section class="section-padding-ash">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="section-title text-center">
          <h2>Our Services</h2>
          <!-- <h6>Established fact that a reader will be distracted by the readable content</h6> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 service_styling">
        <div class="services-cont">
          <div class="icon-cont"><img class="service_icons_style" src="assets/images/icon_1.png"></div>
          <div class="text-cont">
            <h6 class="service_heading">Omics Data Analysis Solution Using Deep Learning
            </h6>
            <p class="service_des">In the diagnosis and research of cancer, the identification and determination of cancerous status or tissue origin of the tissues/samples is costly and time consuming, and requires abundant knowledge and experience.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 service_styling">
        <div class="services-cont">
          <div class="icon-cont"><img class="service_icons_style" src="assets/images/icon_1.png"></div>
          <div class="text-cont">
            <h6 class="service_heading">AI-based Multi-Omics Big Data Analysis Service</h6>
            <p class="service_des">Large-scale infrastructure and advanced analysis technology are needed to analyze multi-omics data.
              Karte can design new analysis models for complicated interactions of various omics data using AI-based machine learning and deep learning technologies.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 service_styling">
        <div class="services-cont">
          <div class="icon-cont"><img class="service_icons_style" src="assets/images/icon_1.png"></div>
          <div class="text-cont">
            <h6 class="service_heading">Big Data Solution for Biomarker Discovery and Evaluation</h6>
            <p class="service_des">Karte obtains data by sequencing the genome collected from the tumors of patients and discovers biomarkers that can suggest the most suitable treatment method for the patients using the data. With the prediction of tumor mutation burden (TMB), one of the biomarkers, customized treatment methods can be analyzed promptly and accurately.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 service_styling">
        <div class="services-cont">
          <div class="icon-cont"><img class="service_icons_style" src="assets/images/icon_1.png"></div>
          <div class="text-cont">
            <h6 class="service_heading">Big Data Consulting Service</h6>
            <p class="service_des">Along with the recent production of various and abundant omics big data due to the development of genome technology and low production costs, new research is actively being conducted. However, due to rapid increases in the amount of data, researchers have difficulty storing, managing, and analyzing it.</p>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-iphone"></i></div>
          <div class="text-cont">
            <h6>iPhone Development</h6>
            <p>It is a long established fact that a reader will be distracted by the</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-video-clapper"></i></div>
          <div class="text-cont">
            <h6>Online Training</h6>
            <p>It is a long established fact that a reader will be distracted by the</p>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</section>
   
<section id="featureProduct" class="section-padding-feature-products">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="section-title text-center">
          <h2>Our Featured Products</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <img src="assets/images/telo_gen.jpg" class="product_img">
        <div class="section-title text-center">
          <h2 class="product-title">TELO-GEN</h2>
          <p class="feature_product_des">Telomere shortening is one of the 9 critical hallmarks of aging. As we age, our average telomere length shortens (telomere attrition).</p>
        </div>
      </div>

      <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <img src="assets/images/mother_to_be.jpg" class="product_img">
        <div class="section-title text-center">
          <h2 class="product-title">MOTHER-TO-BE</h2>
          <p class="feature_product_des">In your journey to be a mother, we are here to care for your nutrition, diet, mental health, detoxification and well-controlled medical conditions.</p>
        </div>
      </div>

      <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <img src="assets/images/gene.jpg" class="product_img">
        <div class="section-title text-center">
          <h2 class="product-title">GENAI-METHYLATION</h2>
          <p class="feature_product_des">Methylation cycle is an important biochemical process in cells. It controls various bodily functions including anti-inflammation, detoxification, energy production, and emotion balance, etc.</p>
        </div>
      </div>

      <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <img src="assets/images/inborn_error_metabolism.jpg" class="product_img">
        <div class="section-title text-center">
          <h2 class="product-title">IEM : Inborn Errors of Metabolism</h2>
          <p class="feature_product_des">IEM NewBorn Screening Tests and Intervention</p>
        </div>
      </div>

    </div>
  </div>
</section>
<section class="section-padding-ash founder_bg">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="text-center">
          <h2 class="founder_header_section">Meet Our Founding Team</h2>
        </div>
      </div>
    </div>
    <div class="row" >
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 justify-content-center founder_name_div wow fadeInUp">
        <img class="founder_class" src="assets/images/founder_pic.jpg">
        <h4 class="founder_name">Sourav Kundu (Dr. Engg.)</h4>
        <h6 class="founder_designation">Author, Researcher, Entrepreneur, Philosopher</h6>
        <div class="founder_des">

          <p>Sourav loves anything that is new and creative and has to do with Computers, Technology or Business. Because of this, he is still undecided whether art or technology suits him better and he continues to pursue both. He started with pursuing for a Masters in Design Computing at the University of Sydney...

          </p>
        </div>
        
        <p class="hidden-sm-down"><a class="btn btn-md btn-default read_more_button" href="http://www.souravkundu.com/" target="blank" role="button">Read More</a></p>
                
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 justify-content-center founder_name_div wow fadeInUp">
          <img class="founder_class" src="assets/images/founder_pic.jpeg">
          <h4 class="founder_name">Shinzo Asaba</h4>
          <h6 class="founder_designation">CEO</h6>
          <div class="founder_des">
  
            <p>
              Enthusiastic in exponential growth area. Current Focus is AI and HealthCare. Founder of Karte, a healthcare AI company. Other than Health care, running three companies, AK Capital (Investment Company), TAK (family office), and Frijoles (fast casual brrito restaurant). Adding Value by investment, direct management, building team, Mexican Burrito restaurant connect Japan and other area. Expertise in financial transaction and real estate investment. Previously, total 16 years marketing and management experience in AIG Financial Products, Salomon Brothers and BNPP.
  
            </p>
          </div>
          
          <p class="hidden-sm-down"><a class="btn btn-md btn-default read_more_button" href="https://www.linkedin.com/in/shinzo-asaba-7a54aab8/" target="blank" role="button">Read More</a></p>
                  
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 justify-content-center founder_name_div wow fadeInUp">
            <img class="founder_class" src="assets/images/founder_pic_3.png">
            <h4 class="founder_name">Dr. Vince Gao, PhD</h4>
            <h6 class="founder_designation">Researcher, Entrepreneur</h6>
            <div class="founder_des">
    
              <p>Dr. Vince Gao, founder and serves as Chief Executive Officer, has more than 18 years' experience in leading life science R&D and business. Dr. Gao has a deep passion for using high-technology to improve people’s health and quality of life and increase the value of medical services. Most recently he served as the Executive President...
    
              </p>
            </div>
            
            <p class="hidden-sm-down"><a class="btn btn-md btn-default read_more_button" href="https://web.govitatech.com/about-us_en/#board-of-directors-en" target="blank" role="button">Read More</a></p>
                    
            </div>

        <!-- <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 founder_description">
<p>Sourav loves anything that is new and creative and has to do with Computers, Technology or Business. Because of this, he is still undecided whether art or technology suits him better and he continues to pursue both. He started with pursuing for a Masters in Design Computing at the University of Sydney, Australia and went on to complete a Doctor of Engineering in Mechanical Engineering (Control Engineering) from Tokyo Metropolitan University, Japan, in March of 1996.

</p>
<p>Since 1996, Sourav has taught courses in Computer Science, Mechanical Engineering, Creative Design, Artificial Intelligence, Theoretical Optimization, Structural and Engineering Optimization and Business at universities in Japan, USA, Turkey, Australia, Italy, France, The Netherlands, Germany, UK and India. He has created and maintained two of the most popular e-commerce enabled web sites for the world Bengali Community - Calcutta Online and Bengalnet Communications.</p>
<p class="hidden-sm-down"><a class="btn btn-md btn-default read_more_button" href="http://www.souravkundu.com/" target="blank" role="button">Read More</a></p>
        </div> -->
    </div>
  </div>
</section>


<!-- block 2 -->

<section class="section-padding-ash founder_bg">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="text-center">
          <h2 class="founder_header_section">Meet Our Management</h2>
        </div>
      </div>
    </div>
    <div class="row" >
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 justify-content-center founder_name_div wow fadeInUp">
        <img class="founder_class" src="assets/images/coo.jpg">
        <h4 class="founder_name">Albert Leung</h4>
        <h6 class="founder_designation">Chief Operation Officer</h6>
        <div class="founder_des">

          <p>
            Albert Leung has over 20 years of IT and regional management experience of which has managed and deliver many projects for over 18 years in serval Fortune Global 100 companies, including IBM, Manulife, SunLife, system delivery and in the insurance industry. He is passionate in applying technology for business implementation. Most recently held various leadership roles in program delivery, client implementations and business development. 

He also passionate about unlocking business values in traditional business through digital transformation, AI, Blockchain, and data analytic. Albert provides leadership and direction for project delivery, application of IT technology into business and generate results and benefit for customers.

Albert graduated from the University of Toronto in Computer Science with a Bachelor honor degree BSc (Hon), specialist in Information Technology and a Master degree MSc, in Information Management Systems. He also obtained Executive Master in Business Administration (EMBA) from The Chinese University of Hong Kong. Mr. Leung is also a Project Management Professional, Professional Scrum Master, and Fellowship of Life Mangement Insurance.
          </p>
        </div>
        
        <!-- <p class="hidden-sm-down"><a class="btn btn-md btn-default read_more_button" href="http://www.souravkundu.com/" target="blank" role="button">Read More</a></p> -->
                
      </div>

      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 justify-content-center founder_name_div wow fadeInUp">
        <img class="founder_class" src="assets/images/kaustav.jpeg">
        <h4 class="founder_name">Kaustav Chakrabarty</h4>
        <h6 class="founder_designation">Enterprise Architect/Solution Architect/Technology Consulting Professional</h6>
        <div class="founder_des">

          <p>
            Around 2 decades of delivering enterprise level global solutions across various businesses/enterprises and delivering hands on technology solutions/consulting in open stack distributed and decentralised technologies, enterprise security etc. Best in R&D and technology evolution by nature. Working on Technology primarily on Blockchain, IOT, AI, Cloud, Microservices based Digital Transformations ideology extensively.
          </p>
        </div>
        
        <p class="hidden-sm-down"><a class="btn btn-md btn-default read_more_button" href="https://www.linkedin.com/in/kaustav-chakraborty-68242a125/" target="blank" role="button">Read More</a></p>
                
      </div>
        
        <!-- <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 founder_description">
<p>Sourav loves anything that is new and creative and has to do with Computers, Technology or Business. Because of this, he is still undecided whether art or technology suits him better and he continues to pursue both. He started with pursuing for a Masters in Design Computing at the University of Sydney, Australia and went on to complete a Doctor of Engineering in Mechanical Engineering (Control Engineering) from Tokyo Metropolitan University, Japan, in March of 1996.

</p>
<p>Since 1996, Sourav has taught courses in Computer Science, Mechanical Engineering, Creative Design, Artificial Intelligence, Theoretical Optimization, Structural and Engineering Optimization and Business at universities in Japan, USA, Turkey, Australia, Italy, France, The Netherlands, Germany, UK and India. He has created and maintained two of the most popular e-commerce enabled web sites for the world Bengali Community - Calcutta Online and Bengalnet Communications.</p>
<p class="hidden-sm-down"><a class="btn btn-md btn-default read_more_button" href="http://www.souravkundu.com/" target="blank" role="button">Read More</a></p>
        </div> -->
    </div>
  </div>
</section>

<!-- testimonial Section -->

<!-- <section class="testimonials">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="owl-carousel owl-theme testimonials-cont">
        
          <div class="item">
            <div class="testimonial-cont">
            
              <div class="testimonial-client-img aligncenter"><img src="http://demos1.showcasedemos.in/jntuicem2017/html/v1/assets/images/jack.jpg" class="rounded-circle" alt="t-2"></div>
           
              <blockquote>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem 
Ipsum has been the industry's standard dummy text ever since the 1500s, when an 
unknown printer took a galley of type and scrambled it to make a type</blockquote>
              <div class="testimonial-info">
              
              <div class="testimonial-client">
                <h6>Alex Jhonson</h6>
                <p>Designer</p>
              </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="testimonial-cont">
            
              <div class="testimonial-client-img aligncenter"><img src="http://via.placeholder.com/80x80" class="rounded-circle" alt="t-2"></div>
             
              <blockquote>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem 
Ipsum has been the industry's standard dummy text ever since the 1500s, when an 
unknown printer took a galley of type and scrambled it to make a type</blockquote>
              <div class="testimonial-info">
              
              <div class="testimonial-client">
                <h6>Alex Jhonson</h6>
                <p>Designer</p>
              </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="testimonial-cont">
            
              <div class="testimonial-client-img aligncenter"><img src="http://demos1.showcasedemos.in/jntuicem2017/html/v1/assets/images/kiara.jpg" class="rounded-circle" alt="t-2"></div>
            
              <blockquote>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem 
Ipsum has been the industry's standard dummy text ever since the 1500s, when an 
unknown printer took a galley of type and scrambled it to make a type</blockquote>
              <div class="testimonial-info">
              
              <div class="testimonial-client">
                <h6>Alex Jhonson</h6>
                <p>Designer</p>
              </div>
              </div>
            </div>
          </div>
          
          
        </div>
      </div>
    </div>
  </div>
</section> -->

