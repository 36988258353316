<div class="container container_spacing">
    <div class="row">
        <div class="col-md-12">
            <h2 style="color: #676767; border-bottom: 1px solid #b3b3b3; padding-bottom: 10px;"><span style="color: #3d8d61;"> Steps for Karte Age app and test.</span></h2>
            
        </div>
        <div class="col-md-6 ">
            <div class="col-12 para_spacing wow fadeInLeft">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">The customer downloads the KarteAge app.​</p>
            </div>
            <div class="col-12 para_spacing wow fadeInLeft">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Customer orders and pays for the test.​</p>
            </div>
            <div class="col-12 para_spacing wow fadeInLeft">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">As soon as payment is done, an ID is issued.</p>
            </div>
            <div class="col-12 para_spacing wow fadeInLeft">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">A firewall is created between the ID and payment. (there is no possible route to link ID and the
                    payment identity).​</p>
            </div>
            <div class="col-12 para_spacing wow fadeInLeft">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">The payment notification is noted at Karte and the Karte Business office is sending
                    the kit to the customer. The office can’t link the ID to the customer address.​</p>
            </div>
            <div class="col-12 para_spacing wow fadeInLeft">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">The customer scans the kit, the barcode is linked with the ID.​</p>
            </div>
            <div class="col-12 para_spacing wow fadeInLeft">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">The customer spits into the tube and seals the tube.​</p>
            </div>
            <div class="col-12 para_spacing wow fadeInLeft">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">The customer sends the kit in a prepaid envelope (dropping it at a post-office obtaining a registration
                    number.​</p>
            </div>
            <div class="col-12 para_spacing wow fadeInLeft">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">The return address is Karte and no personal identity is revealed on the envelope.​</p>
            </div>
            <div class="col-12 para_spacing wow fadeInLeft">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Test results are streamed back to the customer through the KarteAge App.​</p>
            </div>
            <div class="col-12 para_spacing wow fadeInLeft">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">The customer uses the app to report on life style events as he choses.​</p>
            </div>
            <div class="col-12 para_spacing wow fadeInLeft">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">The choices linked to ID are recorded as well as other customers choices.​</p>
            </div>
            <div class="col-12 para_spacing wow fadeInLeft">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Every three months a summary is streamed on the App to each customer.​</p>
            </div>
            <div class="col-12 para_spacing wow fadeInLeft">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Interventions will be assessed relative to recommendations of American and Canadian National
                    Health associations.​</p>
            </div>
            <div class="col-12 para_spacing wow fadeInLeft">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">KarteAge tests are repeated after 6 months.​</p>
            </div>
            <div class="col-12 para_spacing wow fadeInLeft">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Machine learning and AI will analyze data from all customer against KarteAge data and other health
                    indicators, blood pressure, sugar levels, chronic pain measures, depression measures, sleep measures
                    and a weight will be applied to each intervention.​</p>
            </div>
            <div class="col-12 para_spacing wow fadeInLeft">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">A personalized analysis with a weighted recommendation will be streamed to the end users via the
                    APP. We consider this a life-long partnership.​</p>
            </div>
        </div>
        <div class="col-md-6 ">
            <img class="wow fadeInRight mob_img_style" src="assets/images/procedure_img_1.png" style="width: 77%;">
            <img class="wow fadeInRight mob_img_style" src="assets/images/procedure_img_2.png" style="width: 77%; margin-top: 48%;">
        </div>
        <div class="col-md-12 your_procedure_instruction_styling">
            <h3>Instruction of spitting into the tube</h3>
        </div>
        <div class="col-md-12 container_spacing">
            <h5>Saliva collection</h5>
        </div>
        <div class="col-md-12 spliting_instrusction_spacing">
            <p><i>DO NOT EAT, DRINK, CHEW GUM, BRUSH TEETH, USE MOUTHWASH OR SMOKE FOR AT LEAST 30
                MINUTES BEFORE SALIVA COLLECTION.</i></p>
        </div>
        <div class="col-12 para_spacing spliting_instrusction_spacing wow fadeInLeft">
            <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Before saliva collection, relax your cheeks and gently massage them with your fingers for 15 - 30
                seconds, this helps to generate more saliva.​</p>
        </div>
        <div class="col-12 para_spacing spliting_instrusction_spacing wow fadeInLeft">
            <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Typically, it takes 2 - 5 minutes to complete the saliva collection.​</p>
        </div>
        <div class="col-md-12 container_spacing">
            <h5>Procedure</h5>
        </div>
        <div class="col-md-6">
            <div class="col-md-12 para_spacing spliting_instrusction_spacing wow fadeInLeft">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Spit into the funnel that is attached to the collection tube until the saliva volume reaches the
                    1ml line.</p>
                    <p style="margin-top: 4%;"><i>Remark: Liquid saliva free from bubbles must reach the 1ml line. Under-filling the tube during
                        saliva collection may negatively affect the test results.</i></p>
            </div>
            <div class="col-md-12 para_spacing spliting_instrusction_spacing wow fadeInLeft">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Carefully unscrew the funnel to prevent spillage of the collected saliva from the tube.​</p>
            </div>
            <div class="col-md-12 para_spacing spliting_instrusction_spacing wow fadeInLeft">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Transfer the liquid with the blue cap into the tube with your collected saliva (purple cap tube).
                    Use the supplied purple cap to close the collection tube tightly. Invert and shake the capped
                    tube 10 - 15 times to ensure the saliva and the stabilizer buffer are fully mixed.</p>
            </div>
            <div class="col-md-12 para_spacing spliting_instrusction_spacing wow fadeInLeft">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Attach the barcode sticker on the tube and put the tube inside the ziplock bag.</p>
            </div>
            <div class="col-md-12 para_spacing spliting_instrusction_spacing wow fadeInLeft">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Fill in the consent form and questionnaire. Paste another copy of barcode sticker onto the
                    consent form/questionnaire.</p>
            </div>
            <div class="col-md-12 para_spacing spliting_instrusction_spacing wow fadeInLeft">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Finally, place the Ziploc bag with sample, the completed consent form/questionnaire directly
                    inside the ziplock bag.​</p>
            </div>
            <div class="col-md-12 para_spacing spliting_instrusction_spacing wow fadeInLeft">
                <fa-icon [icon]="faDna" class="dnaIconStyle"></fa-icon><p class="para_style">Please keep the barcode label for checking your result.</p>
            </div>
        </div>
        <div class="col-md-6">
            <img class="wow fadeInRight" src="assets/images/procedure_img_3.png" style="width: 90%;">
        </div>
        
        
    </div>

</div>
